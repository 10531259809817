import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import index from '../views/index.vue'
import info from '../views/info.vue'
import now from '../views/now.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    redirect: '/home',
    component: index,
    children: [{
      path: '/home',
      name: 'home',
      component: Home
    }, {
      path: '/info',
      name: 'info',
      component: info
    }, ]
  },
  {
    path: '/now',
    name: 'now',
    component: now
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
