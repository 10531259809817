<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {


};
</script>
  <style lang="scss">
@import '../style/index.scss';

body {
  overflow-x: hidden;
  font-size: 16px;
}

div,
ul,
li,
span,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
}

li {
  list-style-type: none;
}
</style>
