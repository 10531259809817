import * as API from './index'
export default {
  //提交
  saveZsData: params => {
    return API.POST('zs/saveZsData', params)
  },
  zsUpload: params => {
    return API.File('file/zsUpload', params)
  },

}
