<template>
  <div>
    <div class="info-title">碧莲盛NHT成都印象网络红人盛典：诠释时尚之美，传递潮流态度！</div>
    <div class="info-date">2021-04-16</div>
    <div class="info-line box"></div>
    <div class="rich_media_content js_underline_content
                       " id="js_content" style="visibility: visible;margin-bottom:50px">
      <div style="text-align: center;">
        <img :src="`${$url}news/5/1.gif`" alt="">
      </div>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); text-align: left; line-height: 1.75em; visibility: visible;"><span style="font-family: Optima-Regular, PingFangTC-light; font-size: 14px; letter-spacing: 1px; text-indent: 28px; white-space: pre-wrap; visibility: visible;">聚焦潮流，璀璨高光，新锐不凡的时</span><span style="font-family: Optima-Regular, PingFangTC-light; font-size: 14px; letter-spacing: 1px; text-indent: 28px; white-space: pre-wrap; visibility: visible;">尚力量，在冗长的沉寂后破土复苏，唤醒了按捺已久的人与故事，终将兑现我们对美好生活的温柔期许，也让我们更为坚定地迈向诗和远方，至此，《碧莲盛NHT成都印象网络红人盛典》完美收官。</span><br style="visibility: visible;"></section>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); text-align: left; line-height: 1.75em; visibility: visible;"><br style="visibility: visible;"></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/5/2.png`" alt="">
      </div>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); text-align: left; line-height: 1.75em; visibility: visible;"><br style="visibility: visible;"></section>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); text-align: left; line-height: 1.75em; visibility: visible;"><span style="font-family: Optima-Regular, PingFangTC-light; font-size: 14px; letter-spacing: 1px; text-indent: 28px; white-space: pre-wrap; visibility: visible;">本次盛典以独到有趣的变焦视野，汇集时尚浪潮中鲜活参与者，记录更多年轻人探索时尚更迭的进程，让植发成为通往时尚的新道路，与更多人产生链接。我们希望所有人都能站在时尚的中心，定义自己专属的时尚标签，借用天府之国的魅力和热情向所有人表述和发散植发的含义和品质生活的意义。</span></section>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); text-align: left; line-height: 1.75em; visibility: visible;"><br style="visibility: visible;"></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span></section>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);font-size: 16px;">
        <section style="margin-right: 16px;margin-left: 16px;">
          <section style="margin-top: 10px;margin-bottom: 10px;text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;">
            <section style="display: inline-block;vertical-align: bottom;width: auto;min-width: 10%;flex: 0 0 auto;height: auto;align-self: flex-end;">
              <section powered-by="xiumi.us" style="display: inline-block;width: 57.7778px;vertical-align: top;">
                <section powered-by="xiumi.us" style="text-align: right;color: rgb(29, 131, 255);font-size: 30px;line-height: 1;letter-spacing: 0px;">
                  <p><strong>01</strong></p>
                </section>
              </section>
              <section powered-by="xiumi.us" style="margin-top: -11px;margin-bottom: -7px;text-align: right;font-size: 0px;justify-content: flex-end;transform: translate3d(11px, 0px, 0px);">
                <section style="display: inline-block;width: 42.7431px;height: 10px;vertical-align: top;overflow: hidden;background-color: rgba(29, 131, 255, 0.08);">
                  <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                </section>
              </section>
              <section powered-by="xiumi.us">
                <section style="margin-top: 10px;display: flex;flex-flow: row nowrap;">
                  <section style="margin-right: 8px;display: inline-block;vertical-align: top;width: auto;flex: 44 44 0%;align-self: flex-start;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                  <section style="display: inline-block;vertical-align: top;width: auto;align-self: flex-start;flex: 100 100 0%;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section style="display: inline-block;vertical-align: bottom;width: auto;align-self: flex-end;flex: 100 100 0%;height: auto;">
              <section powered-by="xiumi.us" style="text-align: right;justify-content: flex-end;font-size: 0px;">
                <section style="display: inline-block;width: 415.99px;vertical-align: top;height: auto;">
                  <section powered-by="xiumi.us">
                    <section style="background-color: rgba(29, 131, 255, 0.57);height: 1px;width: 750px;margin-left:-330px">
                      <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                    </section>
                  </section>
                </section>
              </section>
              <section powered-by="xiumi.us" style="padding-right: 7px;padding-left: 7px;text-align: justify;color: rgb(29, 131, 255);">
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-align: left;text-indent: 28px;white-space: normal;">时尚达人红毯直播秀，</strong></strong></strong></strong></strong></strong></strong></span></strong></p>
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-align: left;text-indent: 28px;white-space: normal;">千万级网络流量引燃盛典</strong></strong></strong></strong></strong></strong></strong></span></strong></p>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;letter-spacing: 0.544px;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;font-size: 14px;color: rgb(255, 255, 255);">121</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">盛典现场，各领域KOL、时尚达人、平台博主皆齐聚于此，她们信步于红毯之上，聚焦于灯光之中，华服蹁跹，熠熠夺目，大杀菲林，如同点点星光聚合，照亮成都，传递出植发在新时代下所赋予的潮流魅力，而此刻头发也不再单纯是身体的一部分，更像是时尚灵魂拒绝躲藏的破圈宣誓，让每个人都散发着独特的符号信息。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><br></section>
      <div style="text-align: center;margin-bottom:20px">
        <img :src="`${$url}news/5/3.png`" alt="">
      </div>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span></section>
      <div style="text-align: center;margin-bottom:20px">
        <img :src="`${$url}news/5/4.png`" alt="">
      </div>
      <div style="text-align: center;">
        <img :src="`${$url}news/5/5.png`" alt="">
      </div>
      <section style="white-space: normal;letter-spacing: 0.544px;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;background-color: rgb(255, 255, 255);text-align: center;line-height: 1.75em;"><br><br></section>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);font-size: 16px;">
        <section style="margin-right: 16px;margin-left: 16px;">
          <section style="margin-top: 10px;margin-bottom: 10px;text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;">
            <section style="display: inline-block;vertical-align: bottom;width: auto;min-width: 10%;flex: 0 0 auto;height: auto;align-self: flex-end;">
              <section powered-by="xiumi.us" style="display: inline-block;width: 57.7778px;vertical-align: top;">
                <section powered-by="xiumi.us" style="text-align: right;color: rgb(29, 131, 255);font-size: 30px;line-height: 1;letter-spacing: 0px;">
                  <p><strong>02</strong></p>
                </section>
              </section>
              <section powered-by="xiumi.us" style="margin-top: -11px;margin-bottom: -7px;text-align: right;font-size: 0px;justify-content: flex-end;transform: translate3d(11px, 0px, 0px);">
                <section style="display: inline-block;width: 42.7431px;height: 10px;vertical-align: top;overflow: hidden;background-color: rgba(29, 131, 255, 0.08);">
                  <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                </section>
              </section>
              <section powered-by="xiumi.us">
                <section style="margin-top: 10px;display: flex;flex-flow: row nowrap;">
                  <section style="margin-right: 8px;display: inline-block;vertical-align: top;width: auto;flex: 44 44 0%;align-self: flex-start;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                  <section style="display: inline-block;vertical-align: top;width: auto;align-self: flex-start;flex: 100 100 0%;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section style="display: inline-block;vertical-align: bottom;width: auto;align-self: flex-end;flex: 100 100 0%;height: auto;">
              <section powered-by="xiumi.us" style="text-align: right;justify-content: flex-end;font-size: 0px;">
                <section style="display: inline-block;width: 415.99px;vertical-align: top;height: auto;">
                  <section powered-by="xiumi.us">
                    <section style="background-color: rgba(29, 131, 255, 0.57);height: 1px;width: 750px;margin-left:-330px">
                      <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                    </section>
                  </section>
                </section>
              </section>
              <section powered-by="xiumi.us" style="padding-right: 7px;padding-left: 7px;text-align: justify;color: rgb(29, 131, 255);">
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-align: left;text-indent: 28px;white-space: normal;">用植发定义时尚趋势，</strong></strong></strong></strong></strong></strong></strong></span></strong></p>
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-align: left;text-indent: 28px;white-space: normal;">创新才能享受品质生活</strong></strong></strong></strong></strong></strong></strong></span></strong></p>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;letter-spacing: 0.544px;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;font-size: 14px;color: rgb(255, 255, 255);">121</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">实用主义的生存竞争，时代发展的多元交融，幸会在21世纪能够用植发来解读年轻人的世界，针对本次盛典碧莲盛董事长尤丽娜女士说道：碧莲盛始终以高质量、高水平的植发技术帮助人们发现美、创造美。此次聚焦镜头与视频内容呈现的盛典，正是通过人与城市的结合，用丰富和有创意的画面，阐释碧莲盛的美学主张与品牌内涵，帮助发友追求品质生活。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><br></section>
      <div style="text-align: center;margin-bottom:20px"> <img :src="`${$url}news/5/6.png`" alt="">
      </div>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span><br></section>
      <section style="white-space: normal;letter-spacing: 0.544px;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;background-color: rgb(255, 255, 255);text-align: center;line-height: 1.75em;"><br></section>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);font-size: 16px;">
        <section style="margin-right: 16px;margin-left: 16px;">
          <section style="margin-top: 10px;margin-bottom: 10px;text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;">
            <section style="display: inline-block;vertical-align: bottom;width: auto;min-width: 10%;flex: 0 0 auto;height: auto;align-self: flex-end;">
              <section powered-by="xiumi.us" style="display: inline-block;width: 57.7778px;vertical-align: top;">
                <section powered-by="xiumi.us" style="text-align: right;color: rgb(29, 131, 255);font-size: 30px;line-height: 1;letter-spacing: 0px;">
                  <p><strong>03</strong></p>
                </section>
              </section>
              <section powered-by="xiumi.us" style="margin-top: -11px;margin-bottom: -7px;text-align: right;font-size: 0px;justify-content: flex-end;transform: translate3d(11px, 0px, 0px);">
                <section style="display: inline-block;width: 42.7431px;height: 10px;vertical-align: top;overflow: hidden;background-color: rgba(29, 131, 255, 0.08);">
                  <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                </section>
              </section>
              <section powered-by="xiumi.us">
                <section style="margin-top: 10px;display: flex;flex-flow: row nowrap;">
                  <section style="margin-right: 8px;display: inline-block;vertical-align: top;width: auto;flex: 44 44 0%;align-self: flex-start;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                  <section style="display: inline-block;vertical-align: top;width: auto;align-self: flex-start;flex: 100 100 0%;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section style="display: inline-block;vertical-align: bottom;width: auto;align-self: flex-end;flex: 100 100 0%;height: auto;">
              <section powered-by="xiumi.us" style="text-align: right;justify-content: flex-end;font-size: 0px;">
                <section style="display: inline-block;width: 415.99px;vertical-align: top;height: auto;">
                  <section powered-by="xiumi.us">
                    <section style="background-color: rgba(29, 131, 255, 0.57);height: 1px;width: 750px;margin-left:-330px">
                      <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                    </section>
                  </section>
                </section>
              </section>
              <section powered-by="xiumi.us" style="padding-right: 7px;padding-left: 7px;text-align: justify;color: rgb(29, 131, 255);">
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-align: left;text-indent: 28px;white-space: normal;">聚焦颁奖高光时刻，</strong></strong></strong></strong></strong></strong></strong></span></strong></p>
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-align: left;text-indent: 28px;white-space: normal;">潮出榜young新势力</strong></strong></strong></strong></strong></strong></strong></span></strong></p>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;letter-spacing: 0.544px;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;font-size: 14px;color: rgb(255, 255, 255);">121</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">本次盛典以城市与各领域KOL相结合的方式，整整15部短片承载着碧莲盛对成都人文、历史、自然的解读，向所有人诠释出植发与时尚的紧密链接，并在盛典现场对各网络红人的Vlog视频进行了公平、公正的评选，根据视频创意、内容输出、受欢迎度等多个方面为参考，进行奖项颁发。</span></section>
      <p style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><br></p>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">这是对各网络红人为传递时尚概念给予的肯定，在生活中，她们以最young的视角看待世界，以多元的口吻记录真实，以潮流的态度面向大众，此刻她们通过植发获得了美好生活的开端，而这也是碧莲盛不断创新植发技术，通过NHT不剃发技术实现时尚打造、品牌升级的意义所在，“闪耀于成都，不止于成都”，碧莲盛将不断践行初衷，努力开创植发与时尚升级的新纪元。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><br></section>
      <div style="text-align: center;margin-bottom:20px"> <img :src="`${$url}news/5/7.png`" />
      </div>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span></section>
      <div style="text-align: center;margin-bottom:20px">
        <img :src="`${$url}news/5/8.png`" />
      </div>
      <div style="text-align: center;margin-bottom:20px">
        <img :src="`${$url}news/5/9.png`" />
      </div>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;margin-left: 8px;margin-right: 8px;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">&nbsp;</span><br></section>
      <section style="white-space: normal;letter-spacing: 0.544px;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;background-color: rgb(255, 255, 255);text-align: center;line-height: 1.75em;"><br></section>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);font-size: 16px;">
        <section style="margin-right: 16px;margin-left: 16px;">
          <section style="margin-top: 10px;margin-bottom: 10px;text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;">
            <section style="display: inline-block;vertical-align: bottom;width: auto;min-width: 10%;flex: 0 0 auto;height: auto;align-self: flex-end;">
              <section powered-by="xiumi.us" style="display: inline-block;width: 57.7778px;vertical-align: top;">
                <section powered-by="xiumi.us" style="text-align: right;color: rgb(29, 131, 255);font-size: 30px;line-height: 1;letter-spacing: 0px;">
                  <p><strong>04</strong></p>
                </section>
              </section>
              <section powered-by="xiumi.us" style="margin-top: -11px;margin-bottom: -7px;text-align: right;font-size: 0px;justify-content: flex-end;transform: translate3d(11px, 0px, 0px);">
                <section style="display: inline-block;width: 42.7431px;height: 10px;vertical-align: top;overflow: hidden;background-color: rgba(29, 131, 255, 0.08);">
                  <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                </section>
              </section>
              <section powered-by="xiumi.us">
                <section style="margin-top: 10px;display: flex;flex-flow: row nowrap;">
                  <section style="margin-right: 8px;display: inline-block;vertical-align: top;width: auto;flex: 44 44 0%;align-self: flex-start;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                  <section style="display: inline-block;vertical-align: top;width: auto;align-self: flex-start;flex: 100 100 0%;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section style="display: inline-block;vertical-align: bottom;width: auto;align-self: flex-end;flex: 100 100 0%;height: auto;">
              <section powered-by="xiumi.us" style="text-align: right;justify-content: flex-end;font-size: 0px;">
                <section style="display: inline-block;width: 415.99px;vertical-align: top;height: auto;">
                  <section powered-by="xiumi.us">
                    <section style="background-color: rgba(29, 131, 255, 0.57);height: 1px;width: 750px;margin-left:-330px">
                      <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                    </section>
                  </section>
                </section>
              </section>
              <section powered-by="xiumi.us" style="padding-right: 7px;padding-left: 7px;text-align: justify;color: rgb(29, 131, 255);">
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-align: left;text-indent: 28px;white-space: normal;">跨界探寻&nbsp;</strong></strong></strong></strong></strong></strong></strong></span></strong></p>
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-align: left;text-indent: 28px;white-space: normal;">拓展生活美学的想象边缘</strong></strong></strong></strong></strong></strong></strong></span></strong></p>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;letter-spacing: 0.544px;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;font-size: 14px;color: rgb(255, 255, 255);">12</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">面对新时代下年轻人的生活方式，潮流与时尚永远不是自嗨，而是交流与致敬，颁奖过后，众多专家、达人、媒体相互沟通，激发灵感，去触达惯性思考的意外之地，力求更好的将植发与时尚相融合，碧莲盛深知故步自封，只能停滞不前，只有不断创新，不断与各领域KOL交流，才能更准确的把握企业在植发领域中的定位，将“用技术创造美”的使命进行更好的贯彻，从而为发友提供更为舒适的植发体验。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><br></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/5/10.jpg`" alt="">
      </div>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span></section>
      <section style="white-space: normal;letter-spacing: 0.544px;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;background-color: rgb(255, 255, 255);text-align: center;line-height: 1.75em;"><br><br></section>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);font-size: 16px;">
        <section style="margin-right: 16px;margin-left: 16px;">
          <section style="margin-top: 10px;margin-bottom: 10px;text-align: center;justify-content: center;display: flex;flex-flow: row nowrap;">
            <section style="display: inline-block;vertical-align: bottom;width: auto;min-width: 10%;flex: 0 0 auto;height: auto;align-self: flex-end;">
              <section powered-by="xiumi.us" style="display: inline-block;width: 57.7778px;vertical-align: top;">
                <section powered-by="xiumi.us" style="text-align: right;color: rgb(29, 131, 255);font-size: 30px;line-height: 1;letter-spacing: 0px;">
                  <p><strong>05</strong></p>
                </section>
              </section>
              <section powered-by="xiumi.us" style="margin-top: -11px;margin-bottom: -7px;text-align: right;font-size: 0px;justify-content: flex-end;transform: translate3d(11px, 0px, 0px);">
                <section style="display: inline-block;width: 42.7431px;height: 10px;vertical-align: top;overflow: hidden;background-color: rgba(29, 131, 255, 0.08);">
                  <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                </section>
              </section>
              <section powered-by="xiumi.us">
                <section style="margin-top: 10px;display: flex;flex-flow: row nowrap;">
                  <section style="margin-right: 8px;display: inline-block;vertical-align: top;width: auto;flex: 44 44 0%;align-self: flex-start;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                  <section style="display: inline-block;vertical-align: top;width: auto;align-self: flex-start;flex: 100 100 0%;height: auto;">
                    <section powered-by="xiumi.us">
                      <section style="background-color: rgba(29, 131, 255, 0.57);height: 2px;">
                        <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section style="display: inline-block;vertical-align: bottom;width: auto;align-self: flex-end;flex: 100 100 0%;height: auto;">
              <section powered-by="xiumi.us" style="text-align: right;justify-content: flex-end;font-size: 0px;">
                <section style="display: inline-block;width: 415.99px;vertical-align: top;height: auto;">
                  <section powered-by="xiumi.us">
                    <section style="background-color: rgba(29, 131, 255, 0.57);height: 1px;width: 750px;margin-left:-330px">
                      <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                    </section>
                  </section>
                </section>
              </section>
              <section powered-by="xiumi.us" style="padding-right: 7px;padding-left: 7px;text-align: justify;color: rgb(29, 131, 255);">
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-size: 16px;white-space: normal;background-color: rgb(255, 255, 255);font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong>绽放红人价值&nbsp;</strong></strong></strong></strong></strong></span></strong></strong></strong></strong></strong></strong></strong></span></strong></p>
                <p><strong style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong><strong><strong style="color: rgb(29, 131, 255);font-size: 16px;white-space: normal;background-color: rgb(255, 255, 255);font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-align: left;text-indent: 28px;"><span style="line-height: 25.6px;min-height: 0px;background-image: none;background-clip: border-box;background-position: 0% 0%;background-repeat: repeat;background-size: auto;border-width: 0px;border-style: none;border-color: rgb(29, 131, 255);bottom: auto;height: auto;left: auto;max-height: none;min-width: 0px;text-decoration-style: solid;text-decoration-color: rgb(29, 131, 255);top: auto;z-index: auto;visibility: visible;clear: none;"><strong><strong><strong><strong><strong>创造美好生活</strong></strong></strong></strong></strong></span></strong></strong></strong></strong></strong></strong></strong></span></strong></p>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;letter-spacing: 0.544px;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;font-size: 14px;color: rgb(255, 255, 255);">121</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">此次举办成都印象网络红人盛典，不论是对行业还是对个人，都将带来不俗的价值。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">于行业而言：碧莲盛NHT成都印象网络红人盛典将变成植发行业的助推器，带动企业向品质、时尚、美好的领域迈进，并且通过红人的影响力让更多发友了解植发，让整个植发行业处于一个健康、安全、透明的状态下，从而更好的满足人们对美好生活的需求。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">于个人而言：碧莲盛NHT成都印象网络红人盛典为更多爱美的年轻人指明方向，植发已经是实现时尚、创造美好的一种新的形式，传统植发方式所存在的诟病，已经通过创新型的技术解决，每个人都可以拥有新的形象和气质，享受品质生活。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><br></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);text-align: left;line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span></section>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
</style>