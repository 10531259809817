<template>
  <div class="footer">
    <div class="footer-item box">
      <div>
        <div>其他</div>
        <div class="footer-text">电话：400-006-8620</div>
        <div class="footer-text">邮箱：zhaosheng3@smkedu.cn</div>
        <div class="footer-text">地址：成都市武侯区广福桥街12号1栋1单元A1212号</div>
      </div>
      <div>
        <div class="footer-last">
          <div class="footer-code">
            <div>
              <img :src="`${$url}/code/1.png`" alt="" />
            </div>
            <div class="Code-text">技术巡讲公众号</div>
          </div>
          <div class="footer-code">
            <div>
              <img :src="`${$url}/code/2.png`" alt="" />
            </div>
            <div class="Code-text">参会咨询</div>
          </div>
          <div class="footer-code">
            <div>
              <img :src="`${$url}/code/3.png`" alt="" />
            </div>
            <div class="Code-text">报名入口</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box filings" style="text-align:center">
      Copyright © www.smkedu.cn, All Rights Reserved. 盛美康教育
      <a style="color: inherit" href="http://beian.miit.gov.cn" target="_blank">蜀ICP备2023021428号-1</a>
      <img :src="`${$img}/%E5%A4%87%E6%A1%88%E5%9B%BE%E6%A0%87.png`" alt="" style="vertical-align: bottom; margin: -3px 5px; margin-right: 2px" />
      <a target="_blank" style="color: inherit;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51010702003665">
        川公网安备 51010702003665号</a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    gotoPath(a) {
      this.$route.name == 'home' ? this.$emit('directPath',a) : this.$router.push({ name: 'home',params: { id: a } })
    }
  }
}
</script>

<style lang="scss">
.footer {
  padding-top: 40px;
  padding-bottom: 20px;
  background: #1b2327;
  color: #ffffff;
  .footer-item {
    display: flex;
    justify-content: space-between;
    > div {
      margin-top: 10px;
      cursor: pointer;
      > div {
        margin-bottom: 20px;
      }
      .footer-text {
        font-size: 14px;
        color: #cfcfcf;
      }
    }
    > div:last-child {
      .footer-last {
        display: flex;
        text-align: center;
        > div:nth-child(1),
        > div:nth-child(2) {
          margin-right: 20px;
        }
      }
      .Code-text {
        margin-top: 12px;
        font-size: 12px;
      }
      img {
        width: 90px;
      }
    }
  }

  .filings {
    font-size: 12px;
    color: #727272;
    padding-top: 18px;
    border-top: 1px solid #2d3234;
    a {
      text-decoration: none;
    }
  }
}
</style>
