<template>
  <div>
    <div class="info-title">碧莲盛携“不剃发植发技术”参加亚洲毛发移植大会</div>
    <div class="info-date">2023-08-05</div>
    <div class="info-line box"></div>
    <div class="rich_media_content js_underline_content
                       autoTypeSetting24psection
            " id="js_content" style="visibility: visible;margin-bottom:50px">
      <section style="letter-spacing: 2px; line-height: 2; font-size: 16px; visibility: visible;" data-mpa-powered-by="yiban.io">
        <section style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; visibility: visible;" powered-by="xiumi.us">
          <section style="vertical-align: middle; display: inline-block; line-height: 0; width: 90%; height: auto; visibility: visible;">
            <img :src="`${$url}news/2/1.jpg`" alt="">
          </section>
        </section>
      </section>
      <section style="margin-top: 20px; margin-bottom: 20px; visibility: visible;" powered-by="xiumi.us">
        <section style="color: rgb(102, 102, 102); padding-right: 20px; padding-left: 20px; visibility: visible;">
          <p style="visibility: visible;">“长发飘逸却深受发际线困扰，但在使用‘<strong style="visibility: visible;"><span style="color: rgb(46, 106, 214); visibility: visible;">不剃发植发技术</span></strong>’进行手术后，能完美地将发际线的缺陷修复。”这是碧莲盛咨询顾问、发友门琳琳在会议现场给多国植发专家留下的印象。<br style="visibility: visible;"></p>

          <p style="visibility: visible;">近日，<strong style="visibility: visible;"><span style="color: rgb(0, 0, 0); visibility: visible;">第七届亚洲毛发移植大会</span></strong>（AAHRS Meeting）在泰国曼谷召开。来自全球各地的植发领域专家学者及企业界人士莅临现场，并就脱发疾病的药物临床进展、新型诊疗技术及毛发移植等议题进行交流。</p>

          <p style="visibility: visible;">其中，中国植发行业<strong style="visibility: visible;"><span style="color: rgb(46, 106, 214); visibility: visible;">碧莲盛医疗</span></strong>（下称“碧莲盛”）受邀参与此次会议，在会议现场，碧莲盛全面展示了“不剃发植发技术”。</p>
        </section>
      </section>
      <section style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0; visibility: visible;" powered-by="xiumi.us">
        <section style="vertical-align: middle; display: inline-block; line-height: 0; width: 90%; height: auto; visibility: visible;">
          <img :src="`${$url}news/2/2.jpg`" alt="">
        </section>
      </section>
      <section style="font-size: 14px;color: rgb(102, 102, 102);padding-right: 20px;padding-left: 20px;" powered-by="xiumi.us">
        <p style="text-wrap: wrap;">图为亚洲毛发移植大会，碧莲盛正在展示“不剃发植发技术”</p>
      </section>
      <section style="margin-top: 40px;margin-bottom: 40px;" powered-by="xiumi.us">
        <section style="color: rgb(102, 102, 102);padding-right: 20px;padding-left: 20px;">
          <p style="text-wrap: wrap;">成立于2005年，碧莲盛目前共拥有42家全国直营的连锁分院、100余位执业医生，700余位执业护士，其中，主任和副主任医师10余位，为至少<strong><span style="color: rgb(0, 0, 0);">35万发友</span></strong>解决了脱发困扰。<br></p>

          <p style="text-wrap: wrap;">碧莲盛是国内<strong><span style="color: rgb(46, 106, 214);">第一家引进FUE</span></strong>（毛囊单位钻取术）的企业。2002年，新技术FUE面世并引入国内，当时成立仅3年的碧莲盛率先摒弃FUT（毛囊单位头皮条切取术）有痕植发，在连锁医疗机构中全面应用FUE技术。</p>

          <p style="text-wrap: wrap;">为了满足发友们日益增长的需求，从2015年起，碧莲盛开始从很短的头发研究不剃发植发技术。从将后枕部的头发延长取出再种植至前额区域，到<strong><span style="color: rgb(0, 0, 0);">完全不剃发提取并种植</span></strong>，碧莲盛潜心钻研，历时近6年。2020年8月，该项技术被正式推出。</p>
        </section>
      </section>
      <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;" powered-by="xiumi.us">
        <section style="vertical-align: middle;display: inline-block;line-height: 0;width: 90%;height: auto;">
          <img :src="`${$url}news/2/3.jpg`" alt="">
        </section>
      </section>
      <section style="margin-top: 40px;margin-bottom: 40px;" powered-by="xiumi.us">
        <section style="color: rgb(102, 102, 102);padding-right: 20px;padding-left: 20px;">
          <p style="text-wrap: wrap;">《2022中国植发行业研究报告》指出，不剃发植发是目前行业前沿的技术，它颠覆了植发的传统，实现了<strong><span style="color: rgb(46, 106, 214);">毛囊提取、种植技术</span></strong>的双重革新，是植发行业的创举。而这一技术的出现，更解决了困扰发友们多年的植发后形象尴尬、出门难等问题。<br></p>

          <p style="text-wrap: wrap;">“由于人们对颜值的需求不断提高，消费者也越来越关注<strong><span style="color: rgb(0, 0, 0);">头发稀疏、发际线不完美</span></strong>等问题。”此次参与第七届亚洲毛发移植大会的中国整形美容协会毛发医学分会会长张菊芳在其个人微博上表示，部分消费者提道其虽想过要植发，但植发后并不能很快正常社交，自己不想顶着很尴尬的形象去工作、生活。而随着当代人对美观的需求，发友的植发选择逐渐从剃发过渡到不剃发，甚至长发种植。</p>

          <p style="text-wrap: wrap;">在本次大会现场，<strong><span style="color: rgb(46, 106, 214);">碧莲盛董事长尤丽娜</span></strong>以真人案例的形式讲解了“不剃发植发技术”的技术理念、技术成果，以及未来的发展方向。</p>

          <p style="text-wrap: wrap;">作为美学设计师的门琳琳拥有一头秀丽的长发，但此前备受高额头、发际线稀疏的困扰。2021年8月15日，她在碧莲盛进行了植发手术。门琳琳使用的正是碧莲盛的不剃发植发技术，即<strong><span style="color: rgb(0, 0, 0);">无需剃光头发</span></strong>，通过美学设计、提取、种植等步骤完成发际线的修复。</p>

          <p style="text-wrap: wrap;">尤丽娜解释称“我们的发友门琳琳的植发量为2500个毛囊单位，手术总计用时7个小时。在手术完成近两年后，她的头后枕部并未形成取发后可能造成的<strong><span style="color: rgb(46, 106, 214);">白点缺陷</span></strong>。目前，她的发际线仍保持完好，亦未有脱落迹象。”</p>

          <p style="text-wrap: wrap;">“植发以后，我的发际线变正常了，现在额头的头发也与其他人一样正常生长，再也不会受<strong><span style="color: rgb(0, 0, 0);">发际线高</span></strong>的困扰。”门琳琳在大会现场表示。</p>

          <p style="text-wrap: wrap;">在场诸多学者、专家对此表示赞许和肯定，他们更用“<strong><span style="color: rgb(46, 106, 214);">艺术品</span></strong>”来形容碧莲盛“不剃发植发技术”的成果。</p>
        </section>
      </section>
      <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;" powered-by="xiumi.us">
        <section style="vertical-align: middle;display: inline-block;line-height: 0;width: 90%;height: auto;">
          <img :src="`${$url}news/2/4.jpg`" alt="">
        </section>
      </section>
      <section style="margin-top: 40px;margin-bottom: 40px;" powered-by="xiumi.us">
        <section style="color: rgb(102, 102, 102);padding-right: 20px;padding-left: 20px;">
          <p style="text-wrap: wrap;">因遗传、头发长期受到牵拉、毛囊破坏等多种因素影响，越来越多人正饱受<strong><span style="color: rgb(0, 0, 0);">脱发困扰</span></strong>。脱发已然成为备受全球关注的疾病之一。<br></p>

          <p style="text-wrap: wrap;">根据世界卫生组织统计，在全球超过75亿的人口中约有16亿人被脱发困扰。另据国家卫健委调查数据显示，2019年我国脱发人群已经超2.5亿人。也就是说，平均6人中就有1人脱发，其中男性更容易患<strong><span style="color: rgb(46, 106, 214);">雄激素性脱发</span></strong>，患病率约为21.3%，女性患病率相对较少约为6%。</p>

          <p style="text-wrap: wrap;">“与上一代人的脱发年龄相比，至少提前了20年，<strong><span style="color: rgb(0, 0, 0);">年轻化态势</span></strong>愈发明显。”《中国人头皮健康白皮书》的数据也解释道。</p>

          <p style="text-wrap: wrap;">庞大的脱发群体也预示着巨大的市场空间。咨询机构弗若斯特沙利文的数据显示，在中国，毛发医疗服务市场2020年的规模为184亿元，预计2030年将<strong><span style="color: rgb(46, 106, 214);">涨至1381亿元</span></strong>，规模增速高达750%。</p>

          <p style="text-wrap: wrap;">“一方面，在2.5亿人中国人脱发的窘境之下，市场对植发的需求也变得越来越旺盛；另一方面，随着毛发市场20多年来的发展和人们对<strong><span style="color: rgb(0, 0, 0);">技术和美学</span></strong>要求越来越严谨，行业的政策、监管力度愈发健全，植发的技术也变得愈发先进，而不剃发植发技术是当下和未来毛发行业发展的方向之一。”尤丽娜表示。</p>
        </section>
      </section>
      <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;" powered-by="xiumi.us">
        <section style="vertical-align: middle;display: inline-block;line-height: 0;width: 90%;height: auto;">
          <img :src="`${$url}news/2/5.jpg`" alt="">
        </section>
      </section>
      <section style="margin-top: 40px;margin-bottom: 40px;" powered-by="xiumi.us">
        <section style="color: rgb(102, 102, 102);padding-right: 20px;padding-left: 20px;">
          <p style="text-wrap: wrap;">据碧莲盛披露，截至2023年2月，碧莲盛不剃发植发手术量<strong><span style="color: rgb(46, 106, 214);">已突破3万台</span></strong>，平均毛囊种植数量2200单位，涉及毛囊数量超过6000万。第三方机构的数据也显示，使用不剃发植发技术的用户中有92.8％来自碧莲盛。（来源：央广网）<br></p>
        </section>
      </section>
      <section style="margin-top: 40px;" powered-by="xiumi.us">
        <section style="text-align: center;font-size: 17px;letter-spacing: 1.8px;color: rgb(133, 171, 255);">
          <p><strong style="color: rgb(46, 106, 214);">碧莲盛植发</strong></p>
          <p><span style="color: rgb(102, 102, 102);letter-spacing: 1.8px;">正规医疗 全国连锁</span></p>
          <p><span style="color: rgb(46, 106, 214);"><strong>一站式毛发健康管理中心</strong></span></p>
          <p><span style="color: rgb(102, 102, 102);">帮助你解决头发健康问题</span></p>
          <p><span style="color: rgb(102, 102, 102);"><br></span></p>
        </section>
      </section>
      <section style="text-align: center;margin-top: 10px;margin-bottom: 10px;line-height: 0;" powered-by="xiumi.us">

      </section>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>