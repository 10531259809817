<template>
  <div class="header">
    <div class="header-top box">
      <img :src="`${$url}logo.png`" alt="">
      <div>
        <div class="header-item" @click="gotoPath('home')">首页</div>
        <div class="header-item" @click="gotoPath('experts')">专家介绍</div>
        <div class="header-item" @click="gotoPath('Previous')">往届风彩</div>
        <div class="header-item" @click="gotoPath('Excellent')">优秀案例</div>
        <div class="header-item" @click="gotoPath('Technology')">参会须知</div>
        <div @click="$router.push('now')">
          <img :src="`${$url}phone.png`" alt="">
          立即报名
        </div>

      </div>
    </div>
    <img :src="`${$url}banner2.png`" alt="">
  </div>
</template>

<script>
export default {
  methods: {
    gotoPath(a) {
      this.$route.name == 'home' ? this.$emit('directPath',a) : this.$router.push({ name: 'home',params: { id: a } });
    },
    gotoApplication() {
      window.open("https://activity.drlianzhuren.com/recruit")
    }
  }
}
</script>

<style lang="scss">
.header {
  .header-top {
    height: 80px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > img {
      width: 120px;
    }
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      .header-item {
        position: relative;
        &:hover {
          color: rgb(57, 137, 252);
          &::after {
            background: rgb(57, 137, 252);
            width: 100%;
            height: 2px;
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0;
          }
        }
      }
      > div {
        cursor: pointer;
        margin-right: 36px;
        color: #333333;

        &:last-child {
          width: 140px;
          height: 40px;
          background: #ff9d43;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 9px;
          margin-right: 0;
          img {
            width: 14px;
            margin-right: 12px;
          }
        }
      }
    }
  }
  > img {
    width: 100%;
  }
}
</style>