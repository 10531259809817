<template>
  <div>
    <div class="info-title">不剃发植发！碧莲盛登录新潮传媒，解决“秃”如其来的“头”等大事</div>
    <div class="info-date">2021-09-26</div>
    <div class="info-line box"></div>
    <div class="rich_media_content js_underline_content" id="js_content" style="visibility: visible;">
      <p style="margin-left: 8px; margin-right: 8px; line-height: 1.75em; visibility: visible;"><span style="font-size: 15px; visibility: visible;">换完报告网数据显示，“秃”如其来的问题，困扰着2.5亿中国人，其中69.8%是30岁以下的年轻人！那些现在毛发还算浓密的人，也活得并不安心，熬夜追剧，通宵做PPT，总觉得自己头上悬挂了一把机关枪，不知道什么时候就开始秃秃秃秃秃……</span></p>

      <p style="margin-left: 8px; margin-right: 8px; line-height: 1.75em; visibility: visible;"><span style="font-size: 15px; visibility: visible;">植发，成为解决“头”等大事的最短路径。但只要一想到为了植发先得剃发，裹上一大块白色的绷带，还隐隐透出红色的血印，大部分人就已经开始打退堂鼓了。在不剃发的情况下完成植发，成为迫切的需求。</span></p>

      <p style="margin-left: 8px; margin-right: 8px; line-height: 1.75em; visibility: visible;"><span style="font-size: 15px; visibility: visible;">碧莲盛以“NHT不剃发植发技术”走在行业前列。或者，这也可以理解成植发行业的“5G”技术革新，意义重大。为了更贴近目标消费群体，碧莲盛刷屏新潮传媒，集中火力展开饱和攻击，输出强大品牌势能。</span></p>

      <section style="box-sizing: border-box; font-size: 16px; visibility: visible;">
        <section style="box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
          <section style="text-align: center; justify-content: center; transform: translate3d(-3px, 0px, 0px); margin: 15px 0% 10px; display: flex; flex-flow: row nowrap; box-sizing: border-box; visibility: visible;">
            <section style="display: inline-block; width: auto; vertical-align: middle; align-self: center; min-width: 10%; max-width: 100%; flex: 0 0 auto; height: auto; border-style: solid; border-width: 1px; border-color: rgb(102, 102, 102); box-shadow: rgb(255, 218, 92) 5px 5px 0px; background-color: rgb(255, 255, 255); box-sizing: border-box; visibility: visible;">
              <section style="box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
                <section style="text-align: right; justify-content: flex-end; transform: translate3d(-12px, 0px, 0px); margin-top: -7px; margin-right: 0%; margin-left: 0%; display: flex; flex-flow: row nowrap; box-sizing: border-box; visibility: visible;">
                  <section style="display: inline-block; width: 33px; vertical-align: middle; box-shadow: rgb(0, 0, 0) 0px 0px 0px; flex: 0 0 auto; height: auto; align-self: center; line-height: 0; background-color: rgb(254, 255, 255); border-width: 0px; box-sizing: border-box; visibility: visible;">
                    <section style="transform: rotateZ(37deg); box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
                      <section style="text-align: left; justify-content: flex-start; transform: translate3d(8px, 0px, 0px); margin: 2px 0% -3px; box-sizing: border-box; visibility: visible;">
                        <section style="display: inline-block; width: 4px; height: 18px; vertical-align: top; overflow: hidden; background-color: rgb(248, 200, 37); border-width: 0px; border-radius: 16px; border-style: none; border-color: rgb(62, 62, 62); box-shadow: rgb(74, 187, 168) 9px -6px 0px; box-sizing: border-box; visibility: visible;">
                          <section style="visibility: visible;"><svg viewBox="0 0 1 1" style="float: left; line-height: 0; width: 0px; vertical-align: top; visibility: visible;"></svg></section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
              <section style="margin-right: 0%; margin-bottom: 4px; margin-left: 0%; text-align: left; justify-content: flex-start; box-sizing: border-box; visibility: visible;" powered-by="xiumi.us">
                <section style="text-align: center; padding-right: 20px; padding-left: 20px; font-size: 18px; color: rgb(102, 102, 102); letter-spacing: 1px; line-height: 2; box-sizing: border-box; visibility: visible;">
                  <p style="margin-right: 8px; margin-left: 8px; white-space: normal; line-height: 1.75em; visibility: visible;"><strong style="visibility: visible;"><span style="font-size: 15px; visibility: visible;">2.5亿“秃家军”</span></strong></p>
                  <p style="margin-right: 8px; margin-left: 8px; white-space: normal; line-height: 1.75em; visibility: visible;"><strong style="visibility: visible;"><span style="font-size: 15px; visibility: visible;">撑开了一个巨大的行业蓝海</span></strong></p>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <p style="margin-left: 8px; margin-right: 8px; line-height: 1.75em; visibility: visible;"><br style="visibility: visible;"></p>
      <p style="margin-left: 8px; margin-right: 8px; line-height: 1.75em; visibility: visible;"><span style="font-size: 15px; visibility: visible;"></span></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">艾媒咨询数据显示，近年来，中国"植发"关键词网络热度始终保持在高位，平均指数在500以上。另据中国健康促进与教育协会数据显示，目前中国秃发人群总量已突破2.5亿大关，且男性脱发明显高于女性，男性脱发人群规模达到1.64亿，也就是说，每4个中国男人，就有一个脱发。</span></p>

      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">从脱发人群年龄段来看，26-30岁人群占比较大达41.9%，其次为31-40岁人群占比达25.4%，18-25岁人群占比达24.7%。也就是说，18-30岁的脱发人群占比达到66.6%，90后成为植发养发消费的绝对主力。</span></p>

      <p style="text-align: center;">
        <img class="rich_pages wxw-img" :src="`${$url}news/1/1.png`">
      </p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><br></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">巨大的需求撑开了一个新消费蓝海。2020年，中国植发医疗服务市场规模为人民币134亿元。预计到2025年，中国植发医疗服务的市场规模将达到人民币378亿元，复合年增长率将为23.0%。</span></p>

      <p style="text-align: center;">
        <img :src="`${$url}news/1/2.png`" alt="">
      </p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><br></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">与传统医疗服务不同的是，毛发医疗服务其实是一种消费医疗服务。目前我国提供植发医疗服务的医疗机构主要分为四类玩家：全国民营连锁植发机构、民营非连锁植发机构、民营医美整容机构植发科室以及公立医院植发科室，其中民营机构占据了90%的市场份额。</span></p>

      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">凭借强大的技术创新能力、完善的服务保障体系与良好的消费者口碑，碧莲秀脱颖而出，走在行业前沿，为整个行业的高质量发展树立了品质标杆。</span></p>

      <section style="box-sizing: border-box;font-size: 16px;">
        <section style="box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: center;justify-content: center;transform: translate3d(-3px, 0px, 0px);-webkit-transform: translate3d(-3px, 0px, 0px);-moz-transform: translate3d(-3px, 0px, 0px);-o-transform: translate3d(-3px, 0px, 0px);margin: 15px 0% 10px;display: flex;flex-flow: row nowrap;box-sizing: border-box;">
            <section style="display: inline-block;width: auto;vertical-align: middle;align-self: center;min-width: 10%;max-width: 100%;flex: 0 0 auto;height: auto;border-style: solid;border-width: 1px;border-color: rgb(102, 102, 102);box-shadow: rgb(255, 218, 92) 5px 5px 0px;background-color: rgb(255, 255, 255);box-sizing: border-box;">
              <section style="box-sizing: border-box;" powered-by="xiumi.us">
                <section style="text-align: right;justify-content: flex-end;transform: translate3d(-12px, 0px, 0px);margin-top: -7px;margin-right: 0%;margin-left: 0%;display: flex;flex-flow: row nowrap;box-sizing: border-box;">
                  <section style="display: inline-block;width: 33px;vertical-align: middle;box-shadow: rgb(0, 0, 0) 0px 0px 0px;flex: 0 0 auto;height: auto;align-self: center;line-height: 0;background-color: rgb(254, 255, 255);border-width: 0px;box-sizing: border-box;">
                    <section style="transform: rotateZ(37deg);-webkit-transform: rotateZ(37deg);-moz-transform: rotateZ(37deg);-o-transform: rotateZ(37deg);box-sizing: border-box;" powered-by="xiumi.us">
                      <section style="text-align: left;justify-content: flex-start;transform: translate3d(8px, 0px, 0px);-webkit-transform: translate3d(8px, 0px, 0px);-moz-transform: translate3d(8px, 0px, 0px);-o-transform: translate3d(8px, 0px, 0px);margin: 2px 0% -3px;box-sizing: border-box;">
                        <section style="display: inline-block;width: 4px;height: 18px;vertical-align: top;overflow: hidden;background-color: rgb(248, 200, 37);border-width: 0px;border-radius: 16px;border-style: none;border-color: rgb(62, 62, 62);box-shadow: rgb(74, 187, 168) 9px -6px 0px;box-sizing: border-box;">
                          <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
              <section style="margin-right: 0%;margin-bottom: 4px;margin-left: 0%;text-align: left;justify-content: flex-start;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="text-align: center;padding-right: 20px;padding-left: 20px;font-size: 18px;color: rgb(102, 102, 102);letter-spacing: 1px;line-height: 2;box-sizing: border-box;">
                  <p style="margin-right: 8px;margin-left: 8px;white-space: normal;line-height: 1.75em;"><strong><span style="font-size: 15px;">自主研发新技术</span></strong></p>
                  <p style="margin-right: 8px;margin-left: 8px;white-space: normal;line-height: 1.75em;"><strong><span style="font-size: 15px;">碧莲盛撬动植发5G黑科技时代</span></strong></p>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><br></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;"></span></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">中国植发大会发布了《毛发移植规范团体标准》，从官方层面确认了植发技术的五次迭代升级，分别是FUT、镊子种植、种植笔、即插即种、不剃发植发。这五次迭代升级可以理解为植发行业的“5G”技术革新。其中，碧莲盛“NHT不剃发植发技术”正式开启植发行业5G黑科技时代，意义重大。</span></p>

      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">自2005年成立以来，碧莲盛始终将植发技术的创新研发当做发展的核心方向，先后推出了SHT、BHT2.0、AFHT等多项植发技术，在更契合中国人毛发特点的同时，根据消费者的不同特点制定精细化的毛发健康方案。</span></p>

      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">植发技术虽然日益成熟，但个性化、定制化、隐私化等方面，消费者有着更高的需求。2020年8月，碧莲盛正式推出了NHT不剃发植发技术，通过技术创新，解决消费者关心的术后尴尬、隐私问题。近日发布的《2021中国植发行业报告》也指出，在"不剃发"领域，碧莲盛市场占有率高达92.3%。</span></p>

      <p style="text-align: center;">
        <img :src="`${$url}news/1/3.png`" alt="">
      </p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><br></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">但这并不是碧莲盛的全部“野心”。它真正想做的是，以“植发医院”为主线，“医疗生发、医疗养发、医疗固发、头皮抗衰”等全产业链协同发展的战略布局。碧莲盛创始人尤丽娜表示：“植发虽然满足了年轻人的爱美之心，但是还未能满足他们养发固发的消费需求，所以，碧莲盛通过产品延伸，推出了莲主任康疗品牌，构建了毛发健康产业生态。”</span></p>

      <p style="text-align: center;">
        <img :src="`${$url}news/1/4.png`" alt="">
      </p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;text-align: center;"><strong><span style="font-size: 14px;color: rgb(136, 136, 136);">碧莲盛创始人尤丽娜</span></strong></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><br></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">作为碧莲盛的全资独立品牌，莲主任康疗于2020年正式上市，目前已在全国32个城市布局，建立了全方位的毛发健康管理体系，为脱发患者提供精细化的头皮毛发康疗流程、一对一的专属顾问服务、量身定制的针对性康疗方案，由内而外地改善头皮毛发健康。</span></p>

      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">2020 年，中国植发手术约为 51.6 万台，占脱发人口 20.64%，渗透率为 0.21%，市场需求远未得到满足，成长空间巨大。通过下沉市场战略，碧链盛迅猛扩张，不断扩大市场份额，为进入资本市场打下坚实基础。</span></p>

      <section style="box-sizing: border-box;font-size: 16px;">
        <section style="box-sizing: border-box;" powered-by="xiumi.us">
          <section style="text-align: center;justify-content: center;transform: translate3d(-3px, 0px, 0px);-webkit-transform: translate3d(-3px, 0px, 0px);-moz-transform: translate3d(-3px, 0px, 0px);-o-transform: translate3d(-3px, 0px, 0px);margin: 15px 0% 10px;display: flex;flex-flow: row nowrap;box-sizing: border-box;">
            <section style="display: inline-block;width: auto;vertical-align: middle;align-self: center;min-width: 10%;max-width: 100%;flex: 0 0 auto;height: auto;border-style: solid;border-width: 1px;border-color: rgb(102, 102, 102);box-shadow: rgb(255, 218, 92) 5px 5px 0px;background-color: rgb(255, 255, 255);box-sizing: border-box;">
              <section style="box-sizing: border-box;" powered-by="xiumi.us">
                <section style="text-align: right;justify-content: flex-end;transform: translate3d(-12px, 0px, 0px);margin-top: -7px;margin-right: 0%;margin-left: 0%;display: flex;flex-flow: row nowrap;box-sizing: border-box;">
                  <section style="display: inline-block;width: 33px;vertical-align: middle;box-shadow: rgb(0, 0, 0) 0px 0px 0px;flex: 0 0 auto;height: auto;align-self: center;line-height: 0;background-color: rgb(254, 255, 255);border-width: 0px;box-sizing: border-box;">
                    <section style="transform: rotateZ(37deg);-webkit-transform: rotateZ(37deg);-moz-transform: rotateZ(37deg);-o-transform: rotateZ(37deg);box-sizing: border-box;" powered-by="xiumi.us">
                      <section style="text-align: left;justify-content: flex-start;transform: translate3d(8px, 0px, 0px);-webkit-transform: translate3d(8px, 0px, 0px);-moz-transform: translate3d(8px, 0px, 0px);-o-transform: translate3d(8px, 0px, 0px);margin: 2px 0% -3px;box-sizing: border-box;">
                        <section style="display: inline-block;width: 4px;height: 18px;vertical-align: top;overflow: hidden;background-color: rgb(248, 200, 37);border-width: 0px;border-radius: 16px;border-style: none;border-color: rgb(62, 62, 62);box-shadow: rgb(74, 187, 168) 9px -6px 0px;box-sizing: border-box;">
                          <section><svg viewBox="0 0 1 1" style="float:left;line-height:0;width:0;vertical-align:top;"></svg></section>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
              <section style="margin-right: 0%;margin-bottom: 4px;margin-left: 0%;text-align: left;justify-content: flex-start;box-sizing: border-box;" powered-by="xiumi.us">
                <section style="text-align: center;padding-right: 20px;padding-left: 20px;font-size: 18px;color: rgb(102, 102, 102);letter-spacing: 1px;line-height: 2;box-sizing: border-box;">
                  <p style="margin-right: 8px;margin-left: 8px;white-space: normal;line-height: 1.75em;"><strong><span style="font-size: 15px;">借力社区梯媒</span></strong></p>
                  <p style="margin-right: 8px;margin-left: 8px;white-space: normal;line-height: 1.75em;"><strong><span style="font-size: 15px;">碧莲盛抢占家庭消费核心场景</span></strong></p>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><br></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">目前，针对脱发、秃头等进行的补偿性措施，如假发、植发、洗护保养等消费意识还处于发育初期，对大多数消费者来说，从“我秃了”到达成“我得去植发”的消费决策，是一个漫长且纠结的过程，并且需要家人共同参与决策。所以，品牌必须与消费者处于共同的场景中，并且通过反复曝光，不断加深消费者的品牌认知。</span></p>

      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><br><span style="font-size: 15px;"></span></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">碧莲盛刷屏新潮传媒，借助社区梯媒扼守全家人“每天必经”的场景优势，开展品牌营销，在“封闭、低扰”的环境里，通过每天数百次高频曝光，强势输出品牌信息，深度渗透全家人共同的核心生活场景，与目标受众达成共识，实现品牌高效传播。</span></p>

      <p style="text-align: center;">
        <img :src="`${$url}news/1/5.png`" alt="">
      </p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><br><span style="font-size: 15px;"></span></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><span style="font-size: 15px;">另一方面，在智能商业时代的大趋势下，技术已成为企业变革和争夺新赛道的关键变量。新潮传媒以创新技术，将全国105座城市的65多万部电梯智慧屏，以数字化的方式链接在一起，帮助品牌实现广告的精准化定向投放，在目标受众群里建立品牌力与影响力。</span></p>
      <p style="text-align: center;"><br></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><em><span style="font-size: 17px;">尤丽娜说：“新消费、新需求、新趋势,消费市场每年都会发生很多变化,但是,无论市场如何变换,最终都要落到产品服务的品质上来,只要不忘初心,坚守品质,就一定能赢得消费者的信任!”</span></em></p>
      <p style="margin-left: 8px;margin-right: 8px;line-height: 1.75em;"><em><span style="font-size: 17px;">&nbsp;</span></em><span style="background-color: rgb(211, 239, 228);text-align: center;font-size: 16px;"></span></p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
</style>