<template>
  <div class="now">
    <div class="typeArea">
      <img src="https://static.drlianzhuren.com/recruit-students/text.png?v=1" alt="">
      <div>碧莲盛不剃发植发理论及技术高级研修</div>
      <div></div>
      <div>城市巡讲（北京站）2023年10月11日-14日</div>
      <div class="frame">
        <div class="now-from">
          <el-form ref="form" label-width="105px" :model="form">
            <el-form-item label="姓名" class="crm-required el-form-item2">
              <el-input v-model="form.name" :maxlength="10" placeholder="请输入" class="input-width"></el-input>
            </el-form-item>
            <el-form-item label="性别" class="crm-required el-form-item2">
              <el-select v-model="form.gender" placeholder="请选择" class="input-width">
                <el-option label="男" :value="0"></el-option>
                <el-option label="女" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机" class="crm-required el-form-item2">
              <el-input v-model="form.mobile" class="input-width" :maxlength="11" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="身份证" class="el-form-item2">
              <el-input v-model="form.identityCardNo" class="input-width" :maxlength="18" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="工作单位" class="el-form-item2">
              <el-input v-model="form.company" class="input-width" :maxlength="20" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="所在城市" class="el-form-item2">
              <el-cascader :options="treeData" class="input-width" @change="handleChange" v-model="value" placeholder="请选择省、市、区" :show-all-levels="true" clearable filterable></el-cascader>
            </el-form-item>
            <el-form-item label="其他">

              <el-input type="textarea" class="input-width2 input-textarea" :maxlength="50" placeholder="请输入" resize="none" v-model="form.remark"></el-input>
            </el-form-item>
            <el-form-item label="资格证上传">
              <div style="overflow:hidden" class="input-width2">
                <el-upload accept="image/jpg,image/png,image/jpeg," action="#" class="avatar-uploader" :file-list="fileList" :on-change="onChange" list-type="picture-card" :auto-upload="false">
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{file}">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </div>
              <div class="remarks">（医师资格证或护士资格证）</div>
            </el-form-item>
            <el-form-item label="">
              <el-button @click="submit" class="input-width submit" style="color:#FFFFFF; height: 36px;background: #FF9D43;border-radius: 4px;border:0">提交</el-button>
            </el-form-item>
          </el-form>

        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>

import treeCityData from '@/utils/areaData'
import API from "@/api/application";
export default {
  data() {
    return {
      value: '',
      form: {
        name: null,
        gender: null,
        mobile: null,
        identityCardNo: '',
        company: '',
        remark: '',
        cardlcon: '',
        province: '',
        city: '',
        district: ''
      },
      disabled: false,
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      treeData: treeCityData,
    }
  },
  created() {

  },
  methods: {
    submit() {
      if (this.form.name == null) {
        this.$message.error('请填写姓名');
        return;
      }
      if (this.form.gender == null) {
        this.$message.error('请选择性别');
        return;
      }
      if (this.form.mobile == null) {
        this.$message.error('请填写手机号');
        return;
      }
      this.uploadFile();
    },
    uploadFile() {
      API.zsUpload(this.fileList).then(res => {
        let list = res.map(item => {
          return item.data.key
        });
        this.form.cardlcon = list.join(",");
        this.save();
      }).catch(err => {
        this.$message.error('上传图片失败，请重试');
      })
    },
    save() {
      API.saveZsData(this.form).then(res => {
        this.$message.success('提交成功');
        setTimeout(() => {
          location.reload();
        },1000)
      }).catch(err => {
        this.$message.error('提交失败，请重试');
      })
    },
    handleRemove(file) {
      let index = this.fileList.findIndex(item => item.url == file.url);
      this.fileList.splice(index,1);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onChange(file) {
      this.fileList.push(file);
    },
    handleChange(list) {
      this.form.province = list[0]
      this.form.city = list[1]
      this.form.district = list[2]
    }
  }
}
</script>

<style lang="scss" scoped>
.now {
  width: 100vw;
  min-height: 100vh;
  background: url('https://static.drlianzhuren.com/recruit-students/bmbg.png?v=1')
    no-repeat;
  background-size: 100% 100%;
  .typeArea {
    text-align: center;
    width: 741px;
    margin: 0 auto;
    > img:nth-child(1) {
      width: 160px;
      margin-top: 20px;
    }

    .frame {
      width: 557px;
      height: 750px;
      margin: 0 auto;
      position: relative;
      background: #ffffff;
      border: 1px solid #ffffff;
      box-shadow: 0px 4px 60px 5px rgba(4, 4, 4, 0.03);
      border-radius: 8px;
      .now-from {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        width: 100%;
        padding: 50px 100px 0 37px;
      }
    }

    > div:nth-child(2) {
      color: #000000;
      text-shadow: 0px 5px 4px rgba(4, 4, 4, 0.1);
      margin-top: 15px;
      font-size: 32px;
      font-weight: 800;
    }
    > div:nth-child(3) {
      width: 100%;
      height: 1px;
      background: #d5d5d5;
      margin: 20px 0;
    }
    > div:nth-child(4) {
      font-size: 18px;
      font-weight: 800;
      color: rgba(0, 0, 0, 0.9);
      margin-bottom: 20px;
    }
  }
  .remarks {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    opacity: 0.4;
    margin-top: 13px;
    text-align: left;
  }
}

::v-deep .el-form-item__label {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}
::v-deep .el-input__inner {
  border: 1px solid #eeeeee !important;
  border-radius: 4px;
  color: #000000;
  border: 0px;
  height: 36px !important;
}
::v-deep .el-textarea__inner {
  border: 1px solid #eeeeee !important;
  border-radius: 4px;
  font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
  color: #000000;
  height: 80px !important;
}
::v-deep .el-input__inner::placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  opacity: 0.6 !important;
}
::v-deep .el-textarea__inner::placeholder {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #000000 !important;
  opacity: 0.6 !important;
}
::v-deep .avatar-uploader {
  display: flex;
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.6);
}
::v-deep .el-upload-list {
  display: flex;
}
::v-deep .avatar-uploader .el-icon-plus {
  transform: translateY(-15px);
}
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 102px;
  height: 102px;
}

::v-deep .el-upload--picture-card {
  width: 102px;
  height: 102px;
  display: block;
}
a:focus {
  outline: none;
  -moz-outline: none;
}
::v-deep .crm-required .el-form-item__label::before {
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}

::v-deep .el-select__caret,
::v-deep .el-icon-arrow-down {
  color: #000000 !important;
  opacity: 0.6;
}
::v-deep .el-icon-plus {
  color: #000000 !important;
  opacity: 0.6;
}
.input-width,
.input-width2 {
  width: 320px;
}
@media screen and (max-width: 1680px) {
  .now {
    width: 100vw;
    min-height: 100vh;
    background: url('https://static.drlianzhuren.com/recruit-students/bmbg.png?v=1')
      no-repeat;
    background-size: 100% 100%;
    .typeArea {
      text-align: center;
      width: 741px;
      margin: 0 auto;
      > img:nth-child(1) {
        width: 100px;
        margin-top: 10px;
      }

      .frame {
        width: 400px;
        height: 520px;
        margin: 0 auto;
        position: relative;
        background: #ffffff;
        border: 1px solid #ffffff;
        box-shadow: 0px 4px 60px 5px rgba(4, 4, 4, 0.03);
        border-radius: 8px;
        .now-from {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          width: 100%;
          padding: 20px 100px 0 0px;
        }
      }

      > div:nth-child(2) {
        margin-top: 5px;
        font-size: 18px;
        font-weight: 800;
        color: #000000;
        text-shadow: 0px 5px 4px rgba(4, 4, 4, 0.1);
      }
      > div:nth-child(3) {
        width: 100%;
        height: 1px;
        background: #d5d5d5;
        margin: 15px 0 8px 0;
      }
      > div:nth-child(4) {
        font-size: 12px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.9);
        margin-bottom: 10px;
      }
    }
    .remarks {
      font-size: 12px;
      font-weight: 400;
      color: #000000;
      opacity: 0.4;
      margin-top: 10px;
      text-align: left;
    }
  }
  .input-width,
  .input-width2 {
    width: 260px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 81px;
    height: 81px;
  }

  ::v-deep .el-upload--picture-card {
    width: 81px;
    height: 81px;
    display: block;
  }

  ::v-deep .el-input__inner {
    color: #000000;
    border: 0px;
    height: 32px !important;
    background: rgba(255, 255, 255, 0.6);
  }
  ::v-deep .el-textarea textarea {
    color: #000000;
  }
  ::v-deep .el-cascader,
  ::v-deep .el-form-item2,
  ::v-deep .input-width {
    height: 32px !important;
  }
  .submit {
    font-size: 12px;
    padding: 0;
  }
  ::v-deep .el-input__inner::placeholder {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #000000 !important;
    opacity: 0.6 !important;
  }
  ::v-deep .el-textarea__inner::placeholder {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #000000 !important;
    opacity: 0.6 !important;
  }
  ::v-deep .el-form-item__label {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
  }
  .input-textarea,
  ::v-deep .el-textarea__inner {
    height: 60px !important;
  }
  ::v-deep .avatar-uploader .el-icon-plus {
    transform: translateY(-28px);
  }
  .el-textarea {
    margin-top: 5px;
  }
}
</style>