<template>
  <div>
    <Header @directPath="directPath"></Header>
    <div class="home">
      <div class="experts" style="overflow: hidden;">
        <div class="allTitle">各大优秀专家集聚一堂</div>
        <div class="allSubheading">.中国毛发移植领域专家齐聚一堂共讨行业前沿，共谋发展大计</div>
        <div>
          <div class="swiper-box">
            <div class="swiper-wrapper">
              <div class="swiper-slide my-swiper-slide" v-for="(item,index) in list" :key="index">
                <div class="experts-box">
                  <div>
                    <div class="experts-top">
                      <div> <img :src="item.src" alt=""></div>
                      <div>{{item.name}}</div>
                    </div>
                    <div class="experts-body">
                      <div class="position">
                        <div>{{item.text1}}</div>
                        <div>{{item.text2}}</div>
                      </div>
                      <div class="brief" v-html="item.brief">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img class="fixed-prev swiper-button-prev" :src="`${$url}experts/left.png`" alt="">
            <img class="fixed-next swiper-button-next" :src="`${$url}experts/right.png`" alt="">
          </div>
        </div>
      </div>
      <div class="Previous">
        <div class="box">
          <div class="allTitle">往届风彩依旧</div>
          <div class="allSubheading">回顾往届的辉煌成就，展望未来的发展前景</div>
          <div class="Previous-box">
            <div>
              <div>
                <img :src="`${$url}Previous/1.png`" alt="">
              </div>
              <div>2023年春季不剃发植发课堂内容</div>
            </div>
            <div>
              <div>
                <img :src="`${$url}Previous/2.png`" alt="">
              </div>
              <div>2022年秋季不剃发植发课堂内容</div>
            </div>
            <div>
              <div>
                <img :src="`${$url}Previous/3.png`" alt="">
              </div>
              <div>2022年春季不剃发植发课堂内容</div>
            </div>
            <div>
              <div>
                <img :src="`${$url}Previous/4.png`" alt="">
              </div>
              <div>2021年秋季不剃发植发课堂内容</div>
            </div>
            <div>
              <div>
                <img :src="`${$url}Previous/5.png`" alt="">
              </div>
              <div>2021年春季不剃发植发课堂内容</div>
            </div>
            <div>
              <div>
                <img :src="`${$url}Previous/6.png`" alt="">
              </div>
              <div>2020年秋季不剃发植发课堂内容</div>
            </div>
          </div>
        </div>
      </div>
      <div class="Excellent box">
        <div class="allTitle">不剃发植发手术案例</div>
        <div class="allSubheading">自然无痕，不剃发植发让你拥有完美发型</div>
        <div class="Excellent-box">
          <img :src="`${$url}Excellent/1.png?v=2`" alt="">
          <img :src="`${$url}Excellent/2.png?v=2`" alt="">
          <img :src="`${$url}Excellent/3.png?v=2`" alt="">
          <img :src="`${$url}Excellent/4.png?v=2`" alt="">
          <img :src="`${$url}Excellent/5.png?v=2`" alt="">
          <img :src="`${$url}Excellent/6.png?v=2`" alt="">
        </div>
      </div>
      <div class="Technology">
        <div class="allTitle">不剃发植发技术</div>
        <div class="allSubheading">500000+客户信赖之选</div>
        <div class="Technology-box box">
          <img :src="`${$url}Technology/1.png`" alt="">
          <img :src="`${$url}Technology/2.png`" alt="">
          <img :src="`${$url}Technology/3.png`" alt="">
          <img :src="`${$url}Technology/4.png`" alt="">
          <img :src="`${$url}Technology/5.png`" alt="">
          <img :src="`${$url}Technology/6.png`" alt="">
        </div>
      </div>
      <div class="news">
        <div class="allTitle">新闻动态</div>
        <div class="allSubheading">实时报道新闻动态，呈现最新事实</div>
        <div class="news-box box">
          <div @click="$router.push({name:'info',params:{id:4}})">
            <div> <img :src="`${$url}news/4.png`" alt=""></div>
            <div>想做植发又犹豫不决，不剃发植发帮你度过尴尬期！</div>
            <div>2023-08-11</div>
          </div>
          <div @click="$router.push({name:'info',params:{id:2}})">
            <div><img :src="`${$url}news/2.png`" alt=""></div>
            <div>碧莲盛携“不剃发植发技术”参加亚洲毛发移植大会</div>
            <div>2023-08-05</div>
          </div>
          <div @click="$router.push({name:'info',params:{id:3}})">
            <div><img :src="`${$url}news/3.png`" alt=""></div>
            <div>不剃发植发，真的一点都不用将头发剃短吗？</div>
            <div>2022-09-26</div>
          </div>
          <div @click="$router.push({name:'info',params:{id:1}})">
            <div><img :src="`${$url}news/1.png`" alt=""></div>
            <div>不剃发植发！碧莲盛登录新潮传媒，解决“秃”如其来的“头”等大事</div>
            <div>2021-09-26</div>
          </div>
          <div @click="$router.push({name:'info',params:{id:5}})">
            <div><img :src="`${$url}news/5.png`" alt=""></div>
            <div>碧莲盛NHT成都印象网络红人盛典：诠释时尚之美，传递潮流态度！</div>
            <div>2021-04-16</div>
          </div>
          <div @click="$router.push({name:'info',params:{id:6}})">
            <div><img :src="`${$url}news/6.png`" alt=""></div>
            <div>技术改变传统丨碧莲盛NHT植发技术发布会即将召开，三大亮点抢先看</div>
            <div>2020-08-12</div>
          </div>
        </div>
      </div>
    </div>
    <Footer @directPath="directPath"></Footer>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import doctor from '../utils/data'
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
export default {
  methods: {
    directPath(a) {
      document.querySelector(`.${a}`).scrollIntoView(true)
    },
    initSwiper() {
      const that = this
      this.mySwiper = new Swiper('.swiper-box',{
        direction: 'horizontal',
        slidesPerView: 4,
        slidesOffsetBefore: 25,
        loopFillGroupWithBlank: true,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        // loop: true,
        // 使用分页器
        observer: true,
        observeParents: true,
        autoplayDisableOnInteraction: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }

      })
    }
  },
  components: {
    Header,
    Footer
  },
  computed: {
    list() {
      return doctor
    }
  },
  mounted() {
    this.initSwiper()
    if (this.$route.params.id) {
      document.querySelector(`.${this.$route.params.id}`).scrollIntoView(true)
    }
  }
}
</script>

<style lang="scss">
.home {
  .experts {
    padding-top: 80px;
    padding-bottom: 80px;
    width: 1190px;
    margin: 0 auto;
    .swiper-box {
      width: 1180px;
    }
    > div:nth-child(3) {
      margin-top: 60px;
      position: relative;
      .fixed-prev {
        width: 13px;
        height: 22.2px;
        position: absolute;
        left: 45px;
        top: 50%;
        z-index: 99;
        transform: translateX(-50%);
      }
      .fixed-next {
        width: 13px;
        height: 22.2px;
        position: absolute;
        right: 10px;
        top: 50%;
        z-index: 99;
        transform: translateX(-50%);
      }
    }

    .experts-box {
      width: 275px;
      height: 400px;
      background: #ffffff;
      box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
      transition: all 0.3s linear;
      -webkit-transition: all 0.3s linear;
      cursor: pointer;
      .experts-top {
        overflow: hidden;
        text-align: center;
        margin-bottom: 15px;
        > div:nth-child(1) {
          width: 187px;
          height: 187px;
          transition: all 0.3s linear;
          -webkit-transition: all 0.3s linear;
          border-radius: 50%;
          overflow: hidden;
          margin: 24px auto 21px;
          border: 1px solid #f3f3f3;
          img {
            width: 100%;
          }
        }
        > div:nth-child(2) {
          font-size: 24px;
        }
      }

      .experts-body {
        text-align: center;
        .position {
          font-size: 16px;
          color: #666666;
          > div {
            line-height: 24px;
          }
        }
        .brief {
          display: none;
          font-size: 16px;
          color: #ffffff;
          line-height: 24px;
        }
      }
      &:hover {
        background: #568bfc;
        box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.1);
        transform: translate3d(0, -14px, 0);
        color: #ffffff;
        .position {
          display: none;
        }
        .experts-top {
          > div:nth-child(1) {
            width: 110px;
            height: 110px;
            margin: 27px auto 27px;
            border: 2px solid #ff9d43;

            img {
              width: 100%;
            }
          }
        }
        .brief {
          display: block;
          font-size: 15px;
          color: #ffffff;
          line-height: 24px;
          width: 240px;
          margin: 0 auto;
        }
      }
    }
  }
  .Previous {
    padding-top: 57px;
    padding-bottom: 60px;
    background: url('https://static.drlianzhuren.com/recruit-students/Previous/bg.png')
      no-repeat;
    background-size: 100% 100%;
    .Previous-box {
      margin-top: 60px;
      justify-content: space-between;
      display: flex;
      flex-wrap: wrap;
      > div {
        > div:nth-child(1) {
          width: 386px;
          overflow: hidden;
          height: 289px;

          img {
            width: 386px;
            transform-origin: center;
            transition: all 0.3s;
            transform: scale(1);
            &:hover {
              transform: scale(1.05);
            }
          }
        }

        > div:nth-child(2) {
          text-align: center;
          font-size: 16px;
          color: #666666;
          margin-bottom: 30px;
          margin-top: 20px;
        }
      }
    }
  }
  .Excellent {
    padding-top: 70px;
    padding-bottom: 60px;
    .Excellent-box {
      margin-top: 58px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      img {
        width: 385px;
        margin-bottom: 20px;
      }
    }
  }
  .Technology {
    padding-top: 80px;
    padding-bottom: 64px;
    background: url('https://static.drlianzhuren.com/recruit-students/Technology/bg.png')
      no-repeat;
    background-size: 100% 100%;
    .Technology-box {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      > img {
        width: 385px;
        background-color: #ffffff;
        margin-bottom: 20px;
        box-shadow: 0 8px 39px 11px rgba(59, 59, 60, 0.04);
      }
    }
  }
  .news {
    padding-top: 70px;
    padding-bottom: 60px;
    .news-box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 60px;
      > div {
        width: 385px;
        height: 390px;
        position: relative;
        margin-bottom: 20px;

        cursor: pointer;

        > div:nth-child(1) {
          width: 385px;
          overflow: hidden;
          height: 306px;
          border: 1px solid #d8d8d8;
          img {
            width: 385px;
            height: 306px;

            transform-origin: center;
            transition: all 0.3s;
            transform: scale(1);
            &:hover {
              transform: scale(1.05);
            }
          }
        }

        > div:nth-child(2) {
          font-size: 16px;
          color: #666666;
          line-height: 21px;
          text-align: justify;
          margin-top: 15px;
        }
        > div:nth-child(3) {
          font-size: 14px;
          margin-top: 8px;
          color: #999999;
        }
      }
    }
  }
}
</style>
