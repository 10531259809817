<template>
  <div>
    <Header></Header>
    <div class="info box">
      <div class="info-Jump"><span>首页</span>&nbsp;&nbsp;&gt;&nbsp;&nbsp;新闻动态详情</div>
      <info1 v-if="id==1"></info1>
      <info2 v-if="id==2"></info2>
      <info3 v-if="id==3"></info3>
      <info4 v-if="id==4"></info4>
      <info5 v-if="id==5"></info5>
      <info6 v-if="id==6"></info6>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
import info1 from '@/components/info1.vue'
import info2 from '@/components/info2.vue'
import info3 from '@/components/info3.vue'
import info4 from '@/components/info4.vue'
import info5 from '@/components/info5.vue'
import info6 from '@/components/info6.vue'
export default {
  components: {
    Header,
    Footer,
    info1,
    info2,
    info3,
    info4,
    info5,
    info6
  },
  data () {
    return {
      id: 1
    }
  },
  created () {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
    } else {
      this.id = 1
    }
  }
}
</script>

<style lang="scss">
.info {
  .info-title {
    text-align: center;
    font-size: 30px;
    color: #111111;
    margin-top: 15px;
  }
  .info-date {
    text-align: center;
    font-size: 14px;
    color: #999999;
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .info-line {
    height: 1px;
    background: #eeeeee;
    margin-bottom: 40px;
  }
  .info-Jump {
    font-size: 14px;
    color: #568bfc;
    line-height: 19px;
    margin-top: 46px;
    > span {
      cursor: pointer;
    }
  }
  img {
    width: 65%;
  }
  p {
    line-height: 25px;
    margin: 10px 0;
  }
}
</style>
