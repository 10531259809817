<template>
  <div>
    <div class="info-title">技术改变传统丨碧莲盛NHT植发技术发布会即将召开，三大亮点抢先看</div>
    <div class="info-date">2020-08-12</div>
    <div class="info-line box"></div>
    <div class="rich_media_content js_underline_content
                       " id="js_content" style="visibility: visible;">
      <div style="text-align: center;">
        <img :src="`${$url}news/5/1.gif`" alt="">
      </div>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); line-height: 1.75em; visibility: visible;"><span style="font-family: Optima-Regular, PingFangTC-light; font-size: 14px; letter-spacing: 1px; text-indent: 28px; white-space: pre-wrap; visibility: visible;"></span></section>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); line-height: 1.75em; visibility: visible;"><span style="font-family: Optima-Regular, PingFangTC-light; font-size: 14px; letter-spacing: 1px; text-indent: 28px; white-space: pre-wrap; visibility: visible;">脱发，一直让发友为之苦恼；植发，也一直让所有专家为之奋斗。从早期的FUT到FUE，再到现在的微针技术，无论从专业水准还是技术角度都有了很大的提升，但随着观念的改变，人们对于植发的要求、对于美的需求都在不断的增大，传统的先剃发再植发术式所带来的漫长等待期、术后尴尬期等弊端已经逐渐让发友产生不适的感觉。</span></section>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); line-height: 1.75em; visibility: visible;"><span style="font-family: Optima-Regular, PingFangTC-light; font-size: 14px; letter-spacing: 1px; text-indent: 28px; white-space: pre-wrap; visibility: visible;"><br style="visibility: visible;"></span></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/1/3.png`" alt="">
      </div>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); line-height: 1.75em; visibility: visible;"><span style="font-family: Optima-Regular, PingFangTC-light; font-size: 14px; letter-spacing: 1px; text-indent: 28px; white-space: pre-wrap; visibility: visible;"><br style="visibility: visible;"></span></section>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); line-height: 1.75em; visibility: visible;"><span style="font-family: Optima-Regular, PingFangTC-light; font-size: 14px; letter-spacing: 1px; text-indent: 28px; white-space: pre-wrap; visibility: visible;">如何让发友们在确保植发效果基础上体验更舒适、感受更美好是碧莲盛一直以来的主要努力方向之一。正所谓发友有需求，研究有动力，碧莲盛在不断探索植发技术中结合时代特质与审美诉求，同时为了让更多发友享受到正规的医疗服务体验，集合专业科研团队研发出拥有自主技术产权的不理发植发技术——NHT，通过在全国32家分院长时间的临床应用上显示，无论从专业上、技术上、发友的反馈上均已表明NHT植发技术是一项已经成熟且能够经受得住考验的植发技术。同时为更好的服务于广大发友，我院将于2020年8月14日在深圳院部召开以“创新引领变革，赋能美好生活”为主题的NHT植发技术发布会。</span></section>
      <section style="margin-right: 8px; margin-left: 8px; white-space: normal; font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif; letter-spacing: 0.544px; background-color: rgb(255, 255, 255); line-height: 1.75em; visibility: visible;"><br style="visibility: visible;"></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/6/1.gif`" alt="">
      </div>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><br></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><strong style="font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-indent: 28px;">碧莲盛NHT植发技术：急您所急，即刻生效</strong><br></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">NHT植发技术是在AFHT美学培固植发技术基础上，升级了取种方式和器械之后形成的革新式植发技术。该技术以长发移植为特点，可以实现长发位移，达到下了手术台，秀发长出来的效果。避免了剃发和术后尴尬期、及生长等待期等环节。非常适合短期内需要出席重要场合或者急需植发的发友群体。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">该技术种植时发干受到打方向孔控制，在同样采取即扎即种的碧莲盛微针种植手法下，使得自然度、生长角度与原生发更加贴近，发型更加自然。另外因为是进行整个发干的移植，微型血痂在发根底部更利于毛囊营养液的留存，配合碧莲盛康养，修复速度更快，整体美观度大幅提高，在视觉上更容易让发友接受。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">进</span><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">行不剃发植发手术是需要高年资医生及种植师进行操作，碧莲盛对于医护及种植师、分离师的眼力、手腕灵活度、年龄、身体素质等有着严苛的要求，在常规植发技能达到优秀之后，还要针对性培训两年以上，才能进行NHT植发技术手术。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/6/2.png`" alt="">
      </div>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;margin-left: 8px;margin-right: 8px;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-indent: 28px;white-space: normal;background-color: rgb(255, 255, 255);"><strong>NHT植发技术优势</strong></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><strong><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></strong></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><strong><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">①术后即刻提升美观度</span></strong><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">由于该技术是将原生发直接移植到脱落区，省去了传统术式中的将后枕部、植发区毛发剃除的环节，所以在种植后可保持原有发干长度不变、发型不变，在下手术台后即可看到植发后最终效果，对于整体美观度的提升也显而易见。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><strong><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">②种植区域与原发融为一体</span></strong></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">不剃发植发技术其实更能明显的看出植发的整体效果，因为它在术后即可就能看出移植的头发和原生发之间的融洽程度、自然度，相对于常规种植技术，不剃发植发技术的种植效果更直观且更容易检验。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><strong><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">③见效快，无植发痕迹及尴尬期</span></strong></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">很多近期需要出镜的公众人物、对形象非常注重的发友，总是很苦恼于术后还有一段时间的短发期或者后枕短发尴尬期，NHT植发技术则可以解决这类问题，并且术后因为毛干较长，头皮取种的痕迹不会明显可见，从而不影响他们的正常工作及生活。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><br></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/6/3.jpg`" alt="">
      </div>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;margin-left: 8px;margin-right: 8px;"><span style="color: rgb(178, 178, 178);font-family: Optima-Regular, PingFangTC-light;font-size: 13.3333px;letter-spacing: 1px;text-align: center;text-indent: 28px;white-space: pre-wrap;background-color: rgb(255, 255, 255);"><span style="color: rgb(178, 178, 178);font-family: Optima-Regular, PingFangTC-light;font-size: 13.3333px;letter-spacing: 1px;text-align: center;text-indent: 28px;white-space: pre-wrap;background-color: rgb(255, 255, 255);">△ </span>发际线不太规则且额角大，影响脸型比例</span></section>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;margin-left: 8px;margin-right: 8px;"><span style="color: rgb(178, 178, 178);font-family: Optima-Regular, PingFangTC-light;font-size: 13.3333px;letter-spacing: 1px;text-align: center;text-indent: 28px;white-space: pre-wrap;background-color: rgb(255, 255, 255);"><br></span></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/6/4.jpg`" alt="">
      </div>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;margin-left: 8px;margin-right: 8px;"><span style="color: rgb(178, 178, 178);font-family: Optima-Regular, PingFangTC-light;font-size: 13.3333px;letter-spacing: 1px;text-align: center;text-indent: 28px;white-space: pre-wrap;background-color: rgb(255, 255, 255);"><span style="color: rgb(178, 178, 178);font-family: Optima-Regular, PingFangTC-light;font-size: 13.3333px;letter-spacing: 1px;text-align: center;text-indent: 28px;white-space: pre-wrap;background-color: rgb(255, 255, 255);">△ </span>刚出手术室，发际线处长发“闪现”</span></section>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;margin-left: 8px;margin-right: 8px;"><span style="color: rgb(178, 178, 178);font-family: Optima-Regular, PingFangTC-light;font-size: 13.3333px;letter-spacing: 1px;text-align: center;text-indent: 28px;white-space: pre-wrap;background-color: rgb(255, 255, 255);"><br></span></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/6/5.jpg`" alt="">
      </div>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="color: rgb(178, 178, 178);font-family: Optima-Regular, PingFangTC-light;font-size: 13.3333px;letter-spacing: 1px;text-align: center;text-indent: 28px;white-space: pre-wrap;background-color: rgb(255, 255, 255);"><span style="color: rgb(178, 178, 178);font-family: Optima-Regular, PingFangTC-light;font-size: 13.3333px;letter-spacing: 1px;text-align: center;text-indent: 28px;white-space: pre-wrap;background-color: rgb(255, 255, 255);">△ </span>术后11天的发际线，完全没有植发痕迹</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;margin-left: 8px;margin-right: 8px;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;font-family: Optima-Regular, PingFangTC-light;font-size: 16px;letter-spacing: 1px;text-indent: 28px;white-space: normal;background-color: rgb(255, 255, 255);"><strong>碧莲盛NHT植发技术发布会</strong></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">碧莲盛将于2020年8月14日在深圳院部召开NHT植发技术发布会，本次发布会的</span><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">召开标志着碧莲盛在植发领域将迈上一个新的台阶，同时这对于整个植发行业来说将是一次新的变革，而对于发友们来说将是效果与颜值的双丰收。</span><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">那么，此次发布会有哪些亮点呢？</span><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">让我们一起先睹为快。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><strong><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">①以发友体验为导向，加速技术迭代创新</span></strong></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">本次大会，将以“创新引领变革，赋能美好生活”为主题，碧莲盛董事长尤丽娜女士也将出席本次发布会，就整个植发行业的发展历程及技术革新发表重要讲话，从而让大家更加直观的感受到专业与技术为发友们带来的便利。针对NHT植发技术在专业层面上进行解读并讲述其对于整个植发行业有何意义以及为何能够高效率的服务于发友。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">碧莲盛董事长尤丽娜女士曾在技术研讨会上分享自己关于创新的看法：创新是一个很容易流于烂俗的词汇，在我们植发行业，更新了器械造型、更新了器械孔径、更新了名称……都可以称之为创新，但是这种创新只是为了创新而进行的逢场作戏，我们应该考虑的是，每一次改变，都要为发友带来什么样的体验，是不是让发友们更放心了、更方便了、更快看到植发效果了。因此，我们的创新要始终牢记以发友体验为导向。</span><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><strong><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">②现场直播演示，亿万发友共同见证</span></strong></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">正所谓“真金不怕火炼”为了让参会的人员更加直观的了解到NHT植发技术的专业性与技术性，本次发布会特别安排了六位不剃发手术患者上台进行术前展示及多名专家现场设计操作，并将通过淘宝、快手、抖音、腾讯直播、大众点评等多家APP平台进行全天候不间断实况直播，届时大家不但可以通过手机观看到现场发布会的盛况，还可以清晰的了解到整个NHT植发技术的手术过程，同时有主播实时讲解手术细节及在线解答网友问题，从而将NHT植发技术完美的呈现在大家面前，共同见证六名发友的完美蜕变。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><br></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/6/6.png`" alt="">
      </div>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><br><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><strong><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">③全天直播不间断，感恩回馈送福利</span></strong></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">有爱，生命才有温度；有你们，碧莲盛才有了如今的厚度。为了感恩回馈每一位支持信赖碧莲盛的发友，凡8月14下午至8月16日到深圳院部的发友均可获得暖心伴手礼包一份（内含毛囊检测、头皮康养、水氧光疗、200毛囊/单位免植券、盛丽洗发水、气垫按摩梳等），同时还能参与现场抽奖活动，养护治脱、豪华电子产品等多重大奖等你来拿。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">除了在活动现场准备的礼品之外，本场发布会上我们还将在各APP平台进行全天候直播，届时碧莲盛将有多城院部进行接力直播，在直播过程中广大发友既可以观看到碧莲盛各分院的院部风采、日常情况还可以通过发送弹幕的方式参与线上1元限时秒杀「不剃发技术超级优惠大礼包」活动，（礼包包含：五折不剃发名额+200单位不剃发体验卡+毛囊检测+ 光疗水养），此外还有全天不间断抽奖，借此发布会之际，向各位发友表达一下感谢之情，感谢大家对碧莲盛的信任，感谢各位发友的一路相伴！</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <div style="text-align: center;">
        <img :src="`${$url}news/6/7.gif`" alt="">
      </div>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">“创新引领变革，赋能美好生活”，8月14日让我们将目光汇聚碧莲盛深圳「NHT植发技术」发布会，共同见证专业的魅力，技术的革新。</span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;"><br></span></section>
      <section style="margin-right: 8px;margin-left: 8px;white-space: normal;font-family: -apple-system-font, system-ui, &quot;Helvetica Neue&quot;, &quot;PingFang SC&quot;, &quot;Hiragino Sans GB&quot;, &quot;Microsoft YaHei UI&quot;, &quot;Microsoft YaHei&quot;, Arial, sans-serif;letter-spacing: 0.544px;background-color: rgb(255, 255, 255);line-height: 1.75em;"><span style="font-family: Optima-Regular, PingFangTC-light;font-size: 14px;letter-spacing: 1px;text-indent: 28px;white-space: pre-wrap;">&nbsp;</span><br></section>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
</style>