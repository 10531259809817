<template>
  <div>
    <div class="info-title">不剃发植发，真的一点都不用将头发剃短吗？</div>
    <div class="info-date">2022-09-26</div>
    <div class="info-line box"></div>
    <div class="info3-box">
      <img :src="`${$url}news/3/1.gif`" alt="">
      <img :src="`${$url}news/3/2.jpg`" alt="">
      <img :src="`${$url}news/3/3.jpg`" alt="">
      <img :src="`${$url}news/3/4.jpg`" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.info3-box {
  text-align: center;
  padding-bottom: 80px;
  img {
    margin-bottom: 20px;
  }
}
</style>