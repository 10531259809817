import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Router from 'vue-router'
import './permission'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.prototype.$url = 'https://static.drlianzhuren.com/recruit-students/'
Vue.prototype.$img = 'https://static.drlianzhuren.com/brandWeb/'

Vue.use(ElementUI);


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
