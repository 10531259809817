import axios from 'axios';

export let base = 'https://manager.blsgroup.com.cn/wechat/';
// export let base = 'https://machine.drlianzhuren.com/wechat/';
export const POST = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.post(`${base}${url}`, params).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err)
    })
  });
}

export const File = (url, params) => {
  const proArr = [] // 多个Promise实例
  params.forEach((item, index) => {
    proArr[index] = new Promise(async (resolve) => {
      let formData = new FormData()
      formData.append('file', item.raw)
      axios.post(`${base}${url}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(res => {
        resolve(res.data);
      }).catch(err => {
        reject(err)
      })
    })
  })
  return Promise.all(proArr);
}


export const GET = (url, params) => {
  return new Promise((resolve, reject) => {
    axios.get(`${base}${url}`, {
      params: params
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err.data)
    })
  });
}
