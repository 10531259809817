const $url = 'https://static.drlianzhuren.com/recruit-students/'

export default [{
    src: `${$url}doctor/1.png`,
    name: '张菊芳',
    text1: '中国整形美容协会',
    text2: '毛发医学分会会长',
    brief: '浙江省医学会医学美学与美容学分副主任委员，整形美容协会抗衰老分会常务理事，中华医学会整形外科分会毛发移植学组组长，主任医师、硕士生导师。'
  },
  {
    src: `${$url}doctor/2.png`,
    name: '吴文育',
    text1: '中国整形美容协会',
    text2: '毛发医学分会候任会长',
    brief: '复旦大学附属华山医院皮肤科，主任医师，皮肤科工会主席，皮肤科手术室主任，植发中心主任，教授，博士生导师。'
  },
  {
    src: `${$url}doctor/3.png`,
    name: '尤丽娜',
    text1: '中整协毛发医学分会副会长',
    text2: '碧莲盛医疗集团董事长',
    brief: '中国毛发移植大会执行主席，中国整形美容协会毛发医学分会副会长，中国整形美容协会医学美学文饰分会副会长，中国整形美容协会毛发医学分会第二届理事会副会长，中国第一位入驻世界植发大会案例中心的医生。'
  },
  {
    src: `${$url}doctor/4.png`,
    name: '杨志祥',
    text1: '主任医师',
    brief: '碧莲盛业务院长，教授、博士研究生导师，碧莲盛植发技术研究中心负责人，中国医师协会美容与整形医师分会顾问，中国医师协会美容与整形医师分会荣誉会员。'
  },
  {
    src: `${$url}doctor/5.png`,
    name: '陈修义',
    text1: '主任医师',
    brief: '碧莲盛植发技术研究中心成员，曾参与不剃发植发技术的研发，碧莲盛植发疼痛管理技术核心成员擅长大面积种植、艺术种植及不剃发植发碧莲盛，植发技术培训中心指导主任。'
  },
  {
    src: `${$url}doctor/6.png?v=1`,
    name: '乔先明',
    text1: '世界植发协会会员',
    text2: '副主任医师',
    brief: '国际医疗美容整形协会会员，中国整形美容医师协会会员，中国首届整形美容白天鹅奖获得者，中国整形美容协会毛发医学分会专家委员，中国整形美容协会医学美学文饰分会副会长，中国中西医结合医学美容专业委员会专家委员。'
  },
  {
    src: `${$url}doctor/7.png?v=1`,
    name: '袁国安',
    text1: '副主任医师',
    brief: '世界植发大会会员，碧莲盛植发技术研究中心核心成员，整形与美容医师协会会员，微创与抗衰老技术委员会委员，参编《无痕植发学》等行业技术参考书籍。'
  },
  {
    src: `${$url}doctor/8.png`,
    name: '蒋学',
    text1: '副主任医师',
    brief: '碧莲盛集团技术总负责人，碧莲盛集团总院长，完全不剃发植发技术研发人，碧莲盛集团医疗技术负责人，碧莲盛植发技术培训中心医疗技术带头人。'
  },
  {
    src: `${$url}doctor/9.png?v=1`,
    name: '苏斌',
    text1: '副主任医师',
    brief: '碧莲盛植发技术研究中心成员，碧莲盛骨干植发医生，曾参与不剃发植发技术的技术研发，擅长艺术类种植、不剃发植发和大面积种植。'
  },
  {
    src: `${$url}doctor/10.png`,
    name: '尹梓贻',
    text1: '副主任医师',
    brief: '碧莲盛植发技术研究中心成员，主导了毛囊培养技术的研发，碧莲盛植发疼痛管理技术核心成员，擅长大面积种植、艺术种植及不剃发植发，拥有十年以上植发临床经验。'
  },
  {
    src: `${$url}doctor/11.png`,
    name: '周宗贵',
    text1: '副主任医师',
    brief: '世界植发大会会员，碧莲盛上海院部技术院长，无痕植发技术数字监控系统建设者，碧莲盛植发技术研究中心核心成员，多次受邀出席植发大会解读碧莲盛植发技术。'
  },

  {
    src: `${$url}doctor/12.png`,
    name: '文亚雄',
    text1: '副主任医师',
    brief: '美容外科主诊医师，碧莲盛名医堂核心成员，碧莲盛植发技术研究中心成员，擅长大面积种植、不剃发植发和艺术类种植。'
  },

  {
    src: `${$url}doctor/13.png`,
    name: '李跟亮',
    text1: '副主任医师',
    brief: '碧莲盛植发技术研究中心成员，碧莲盛太原院部技术负责人，拥有多年不剃发植发技术临床经验，擅长大面积种植、艺术类种植和不剃发植发。'
  },
  {
    src: `${$url}doctor/14.png`,
    name: '张新进',
    text1: '副主任医师',
    brief: '碧莲盛植发技术研究中心成员，曾参与不剃发植发技术的临床研发，多次受邀参与国内外毛发移植大会，擅长艺术类种植、不剃发植发和大面积种植。'
  },

  {
    src: `${$url}doctor/15.png`,
    name: '林海涛',
    text1: '医技总监',
    brief: '碧莲盛不剃发植发集团医技总监，从事植发行业工作20年，拥有多年临床经验，培养了几百余名自体毛发移植医护人员。'
  },
  {
    src: `${$url}doctor/16.png`,
    name: '马彩丽',
    text1: '行业资深专家',
    brief: '曾任海军总医院(现第六医学中心)神经外科住院部护士，不剃发手术术后整体效果及质量把控，院部院感相关培训及其他外联工作，从事植发行业工作20年。'
  },
  {
    src: `${$url}doctor/17.png`,
    name: '单小婷',
    text1: '行业资深专家',
    brief: '碧莲盛北京院部护士长，区域技术总护士长，主管护师，从事种植工作12年碧莲盛优秀种植师高级带教官。'
  },
  {
    src: `${$url}doctor/18.png`,
    name: '祖雪微',
    text1: '行业资深专家',
    brief: '主管护师，毛囊种植师及带教官，参与近万台毛发移植手术，从事植发行业工作12，2022年度碧莲盛高级带教官。'
  }
]
